<template>
  <div class="art bg-white FoundersGrotesk">
    <section class="container-xl">
      <div
        v-for="(rowData, index) in dataRows"
        :key="index"
        class="mb-4 h-100 top-container"
      >
        <b-row class="art-header">
          <b-col class="m-0 p-0" cols="12" md="7">
            <div class="lg-text text-uppercase">THE ARTIST</div>
          </b-col>
          <b-col cols="12" md="5" class="m-0 p-0">
            <b-row class=""></b-row>
            <div
              v-html="formatText(rowData.text)"
              class="md-text fade-in-up-element pb-4"
            ></div>
          </b-col>
        </b-row>
      </div>
      <div class="outer-container">
        <VueSlickCarousel v-bind="settings" class="custom-slick-container">
          <div
            v-for="(image, index) in slickImages"
            :key="index"
            class="artist-container"
          >
            <b-row class="p-0 m-0 w-100">
              <b-col cols="12" md="7" class="left-container bg-white pb-3">
                <div class="text-uppercase sub-header-text pt-2 pb-4">
                  {{ image.name }}
                </div>
                <b-row>
                  <b-col cols="4" lg="4" class="">
                    <div>
                      <div class="text-uppercase sm-header-text">
                        {{ image.roomText }}
                      </div>
                      <div class="sm-text pb-2">{{ image.room }}</div>

                      <!-- Add v-if directive to conditionally display elements -->
                      <div v-if="image.artworkText">
                        <div class="text-uppercase sm-header-text">
                          {{ image.artworkText }}
                        </div>
                        <div class="sm-text pb-2">{{ image.artwork }}</div>
                      </div>

                      <!-- Add v-if directive to conditionally display elements -->
                      <div v-if="image.websiteText">
                        <div class="text-uppercase sm-header-text">
                          {{ image.websiteText }}
                        </div>
                        <a :href="image.websiteLink" target="_blank">
                          <div class="sm-text pb-2">
                            {{ image.website }}
                          </div>
                        </a>
                      </div>

                      <!-- Add v-if directive to conditionally display elements -->
                      <div v-if="image.instagramText">
                        <div class="text-uppercase sm-header-text">
                          {{ image.instagramText }}
                        </div>
                        <a
                          :href="image.instagramLink"
                          class="sm-text pb-2"
                          target="_blank"
                        >
                          <div class="sm-text pb-2">
                            {{ image.instagram }}
                          </div>
                        </a>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="8" lg="8" class="p-0 pr-3">
                    <div
                      v-if="image.eatText"
                      class="text-uppercase sm-header-text"
                    >
                      {{ image.eatText }}
                    </div>

                    <div v-if="image.eat" class="sm-text pb-2">
                      {{ image.eat }}
                    </div>

                    <div
                      v-if="image.MFKText"
                      class="text-uppercase sm-header-text"
                    >
                      {{ image.MFKText }}
                    </div>

                    <div v-if="image.MFK" class="sm-text pb-2">
                      {{ image.MFK }}
                    </div>

                    <div
                      v-if="image.inspirationText"
                      class="text-uppercase sm-header-text"
                    >
                      {{ image.inspirationText }}
                    </div>

                    <div v-if="image.inspiration" class="sm-text pb-2">
                      {{ image.inspiration }}
                    </div>

                    <div
                      v-if="image.sundayText"
                      class="text-uppercase sm-header-text"
                    >
                      {{ image.sundayText }}
                    </div>

                    <div v-if="image.sunday" class="sm-text pb-2">
                      {{ image.sunday }}
                    </div>

                    <div
                      v-if="image.dundasText"
                      class="text-uppercase sm-header-text"
                    >
                      {{ image.dundasText }}
                    </div>

                    <div v-if="image.dundas" class="sm-text pb-2">
                      {{ image.dundas }}
                    </div>

                    <div
                      v-if="image.creationText"
                      class="text-uppercase sm-header-text"
                    >
                      {{ image.creationText }}
                    </div>

                    <div v-if="image.creation" class="sm-text pb-2">
                      {{ image.creation }}
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="5" class="p-0">
                <img
                  :src="image.img"
                  alt="Carousel Image"
                  class="d-block img-fluid w-100 h-100"
                />
              </b-col>
            </b-row>
          </div>
        </VueSlickCarousel>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  data() {
    return {
      settings: {
        arrows: true,
        dots: true,
        dotsClass: "artist slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      text: "hello",
      isOpen: true,
      dataRows: [
        {
          text: "There’s no quicker way to fall in love with a place than to see it through the eyes of somebody who already does. That is why we commissioned Toronto artists to create a unique piece for each of our rooms, so that each experience at Ode is its own thing — a unique variation on a familiar theme. All linked together by the city we love.",
        },
      ],
      slickImages: [
        {
          roomText: "ROOM",
          room: "Global",
          name: "Gillian Toliver",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "gilliantoliver",
          websiteLink: "https://gilliantoliver.format.com/",
          instagramText: "IG",
          instagram: "@gilliantoliver",
          instagramLink:
            "https://www.instagram.com/gilliantoliver/?utm_medium=copy_link",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Honey’s Ice Cream!",
          MFKText: "MARRY, FUCK, KILL: PICASSO, DALI, HILMA AF KLINT?",
          MFK: "Marry Hilma Af Klint, Fuck Hilma Af Klint, Kill Picasso and Dali",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR DRAWING?",
          inspiration:
            "On my bedroom floor I created hundreds of paintings in quick succession. I was painting as if washing the floor with ink, there was no thought or hesitation in my actions. This drawing is of a small section of one of those paintings. This slow translation of paint to graphite is to honour and witness moments of true freedom and creation.",
          img: "/img/home/GillianToliver.png",
        },
        {
          roomText: "ROOM",
          room: "Modernist",
          name: "Jah Grey",
          artworkText: "NAME OF ARTWORK",
          artwork: "Untitled",
          websiteText: "WEBSITE",
          website: "jahgrey.com",
          websiteLink: "https://www.jahgrey.com/",
          instagramText: "IG",
          instagram: "@jahgrey",
          instagramLink: "https://www.instagram.com/jahgrey/",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "I love hanging out at Loveless Cafe",
          sundayText: "WHERE WOULD WE FIND YOU ON A SUNDAY MORNING",
          sunday: "At home in a bath",
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas: "Community",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR DRAWING?",
          inspiration:
            "Expanding our ideas of masculinity. When friendship between men is discussed, it is often described at surface level. Deep, emotional, nuanced, support and connection are rarely a part of the narrative. It needs to be.",
          MFKText: false,
          MFK: false,
          creationText: false,
          creation: false,
          img: "/img/home/JahGrey.png",
        },
        {
          roomText: "ROOM",
          room: "Unbounded",
          name: "Gabriella Lo",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "gabylo",
          websiteLink: "https://gabylo.com/",
          instagramText: "IG",
          instagram: "@gabyguul",
          instagramLink: "https://www.instagram.com/gabyguul/",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Collision.",
          sundayText: "WHERE WOULD WE FIND YOU ON A SUNDAY MORNING",
          sunday: "Sleeping and thinking about going to yoga.",
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas:
            "Great window shopping, people watching, and a place to go meet friends.",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR MURAL?",
          inspiration:
            "I wanted the room to feel like a treehouse in a jungle.",
          MFKText: false,
          MFK: false,
          creationText: false,
          creation: false,
          img: "/img/home/GabriellaLo.png",
        },
        {
          roomText: "ROOM",
          room: "Rooted",
          name: "Justin Broadbent",
          artworkText: "NAME OF ARTWORK",
          artwork: "Full",
          websiteText: "WEBSITE",
          website: "justinbroadbent",
          websiteLink: "https://www.justinbroadbent.com/",
          instagramText: "IG",
          instagram: "@keithdungeon",
          instagramLink: "https://www.instagram.com/keithdungeon/",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Han Moto / Black Dice / Loveless Cafe",
          sundayText: "WHERE WOULD WE FIND YOU ON A SUNDAY MORNING",
          sunday: "Dovercourt park, wrangling my 2 kids.",
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas: "Best spot in Toronto. Everyone is goodlooking.",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR DRAWING?",
          inspiration: "Cats, perseverance, sleep, the word YES.",
          MFKText: "MARRY, FUCK, KILL: PICASSO, DALI, HILMA AF KLINT?",
          MFK: "Marry Hilma, Fuck Picasso (also fuck Picasso), Kill Dali.",
          creationText: false,
          creation: false,
          img: "/img/home/Justin_Broadbent.png",
        },
        {
          roomText: "ROOM",
          room: "Mural",
          name: "Lauren Pirie",
          artworkText: "NAME OF ARTWORK",
          artwork: "Salt in The Shadows",
          websiteText: "WEBSITE",
          website: "laurenpirie",
          websiteLink: "https://laurenpirie.com/",
          instagramText: "IG",
          instagram: "@lapirie",
          instagramLink: "https://laurenpirie.com/",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Honey’s Ice Cream!",
          sundayText: false,
          sunday: false,
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas: "A neighbourhood among neighbourhoods.",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR DRAWING?",
          inspiration:
            "I talked with Ode about embracing intimacy and interconnection between humans — to each other and environment, about contrasting the downtown surroundings with softness, and pointing to Ode’s focus on community and connection. Tiffany encouraging the bum crack on the door really emphasized how aligned a collaboration it was.",
          MFKText: false,
          MFK: false,
          creationText: false,
          creation: false,
          img: "/img/home/LaurenPirie.png",
        },
        {
          roomText: "ROOM",
          room: "Blues",
          name: "THE TORONTO INK COMPANY",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "jasonslogan",
          websiteLink: "http://www.jasonslogan.com/",
          instagramText: "IG",
          instagram: "@toinkcompany",
          instagramLink: "https://www.instagram.com/torontoinkcompany/",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "The Common",
          sundayText: "WHERE WOULD WE FIND YOU ON A SUNDAY MORNING",
          sunday: "West End Railpath scrounging",
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas: "Home palette",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR DRAWING?",
          inspiration: "Childhood + Alchemy",
          MFKText: false,
          MFK: false,
          creationText: "WHEN DID YOU START CREATING ART AND WHY?",
          creation: "Because I got curious.",
          img: "/img/home/JasonLogan.png",
        },
        {
          roomText: "NAME OF CREATION",
          room: "Pink Light Fixture",
          name: "Anony Studio",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "anony",
          websiteLink: "https://anony.ca/",
          instagramText: "IG",
          instagram: "@anonystudio",
          instagramLink: "https://www.instagram.com/anonystudio/",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Leisurely chomping a sando from McCormick Park Cafe while having no plans for the day. Or dressing nicely to contemplate what wine to drink at Giulietta. And eating a slice at Pizza Badiali just because.",
          sundayText: "WHERE WOULD WE FIND YOU ON A SUNDAY MORNING",
          sunday:
            "Milky's! Spending a summer morning on the patio drinking coffee while listening to the regulars talk about the living they’ve done since we last saw them. It is a great place to feel inspired by, and connect with people in my neighbourhood. And the drinks are all worth the sit.",
          dundasText: false,
          dundas: false,
          inspirationText: false,
          inspiration: false,
          MFKText: false,
          MFK: false,
          creationText: false,
          creation: false,
          img: "/img/home/ChristianLo.png",
        },
        {
          roomText: "NAME OF CREATION",
          room: "Interior Designer",
          name: "Alisha Sturino",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "studiootty",
          websiteLink: "https://www.studiootty.com/",
          instagramText: "IG",
          instagram: "@studiootty",
          instagramLink: "https://www.instagram.com/studiootty/?hl=en",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Midfield Wine Bar!! Especially when you go with Giuseppe’s recommendation. I also miss their brunch, but wow the Spanish Hash at Good Fork is heavenly! Ps- I am secretly waiting for Ode to have a rooftop launch / dance party!",
          sundayText: false,
          sunday: false,
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas:
            "A sense of community -  an evolving and always welcoming neighbourhood.",
          inspirationText:
            "WHAT WAS THE INSPIRATION BEHIND THE INTERIOR DESIGN AT ODE?",
          inspiration:
            "Looking at the growing and diverse neighbourhood plus an array of travelers, I imagined coming to enjoy a room at Ode and being able to ‘choose your own adventure’ sort of speak.",
          MFK: false,
          creationText: false,
          creation: false,
          img: "/img/home/AlishaSturino.png",
        },
        {
          roomText: "ROOM",
          room: "Yellow",
          name: "Cassie Suche",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "cassiesuche",
          websiteLink: "https://cassiesuche.com/",
          instagramText: "IG",
          instagram: "@cassiesuche",
          instagramLink: "https://www.instagram.com/cassiesuche/?hl=en",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Loveless cafe for the stripped back ambience and great patio. Great for reading and working, and also for getting hammered. Black Dice Cafe for the best jukebox selections and sake on tap.",
          sundayText: false,
          sunday: false,
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas:
            "Warm, welcoming, and laid back. There is a palpable sense of community and collectiveness. You really can't go wrong here.",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR DRAWING?",
          inspiration:
            "Process, process, process. I like to find new ways of creating and surprising myself. I focus more on the 'how' than the 'what'.",
          MFK: false,
          creationText: false,
          creation: false,
          img: "/img/home/CassieSuche.png",
        },
        {
          roomText: "ROOM",
          room: "Plum",
          name: "RAOUL OLOU",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "raoulolou",
          websiteLink: "https://raoulolou.com/",
          instagramText: "IG",
          instagram: "@raoul_o",
          instagramLink: "https://www.instagram.com/raoul_o/",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "I really like the benches in front of Loveless, and Loveless patio.",
          sundayText: "WHERE WOULD WE FIND YOU ON A SUNDAY MORNING",
          sunday: "HOME! with my phone dead or at 2% battery.",
          dundasText: false,
          dundas: false,
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR ART?",
          inspiration:
            "It changes a lot. I don't think I can pinpoint a specific inspiration for my work in general but right now i'm really interested in ontologies, any phenomenons that help question the nature of our realities and ancient humans histories so yeah maybe science and things that science can't explain inspire me a lot!",
          MFKText: false,
          MFK: false,
          creationText: false,
          creation: false,
          img: "/img/home/RaoulOlou.png",
        },
        {
          roomText: "ROOM",
          room: "Terracotta",
          name: "Tahsine",
          artworkText: false,
          artwork: false,
          websiteText: "WEBSITE",
          website: "tahsinthegood",
          websiteLink: "https://tahsinthegood.com/",
          instagramText: "IG",
          instagram: "@tah_____sine",
          instagramLink: "https://www.instagram.com/tah_____sine/?hl=en",
          eatText: "FAVOURITE PLACE IN THE AREA",
          eat: "Hanmoto for a date, Death in Venice for gelato, Shopcoat for gear.",
          sundayText: false,
          sunday: false,
          dundasText: "WHAT DOES DUNDAS STREET WEST MEAN TO YOU?",
          dundas: "Friendly, Open, cute.",
          inspirationText: "WHAT WAS THE INSPIRATION BEHIND YOUR MURAL?",
          inspiration:
            "Themes of roots / fruits (family), time, growth, imagination, ingenuity, history, place, movement, individuality x the collective show up in this pair. But this is specifically an ode to you x yours.",
          creationText: "TWO TRUTHS AND A LIE",
          creation:
            "I’ve won an award for philosophy, I majored in english, and I read books in Arabic.",
          img: "/img/home/Tahsine.png",
        },
      ],
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {}, 500);
  },
  methods: {
    formatText(text) {
      return text.replace(/\n/g, "<br>");
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.art {
  padding: 50px 0px 50px 0px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(254, 255, 102, 1) 100%
  );
  @media screen and (max-width: 1176px) {
    padding: 40px 0px 50px 0px;
  }
  .art-header {
    @media screen and (max-width: 600px) {
      padding: 0px 0px 30px 0px;
    }
  }
  .link-text {
    letter-spacing: 1px;
  }
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-black;
    text-decoration: none;
    line-height: 10px;
  }
  .top-container {
    @media screen and (max-width: 1176px) {
      padding: 0px 35px 0px 35px;
    }
  }
  .left-container {
    height: 346px;
    @media screen and (max-width: 766px) {
      height: 360px;
    }
    @media screen and (max-width: 500px) {
      height: auto;
    }
  }
  .artist-container {
    border: 2px solid $m-black;
    height: 350px;
    @media screen and (max-width: 766px) {
      height: unset;
    }
  }
  .custom-slick-container {
    width: 84%;
    margin: 0 auto;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
    @media screen and (max-width: 700px) {
      width: 85%;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
  img {
    border-left: 2px solid $m-black;
    @media screen and (max-width: 800px) {
      border: none;
      border-top: 2px solid $m-black;
    }
  }
  button {
    color: black;
  }
}
</style>

<style lang="scss">
@import "./assets/styles/variables";
.art {
  .card {
    border: none;
  }
  .card-header {
    background-color: white;
    border-bottom: none;
    padding: 0;
  }
  .card-body {
    padding: 0;
  }
  .btn {
    text-align: left;
    padding: 0;
  }
  .btn-info {
    background-color: white;
    border: none;
  }
  .btn.btn-info:focus,
  .btn.btn-info:hover,
  .btn.btn-info:active {
    background-color: white;
    border: none;
    color: black;
    box-shadow: none;
    border-radius: 0px;
  }

  .custom-slick-container {
    .slick-prev.slick-arrow {
      display: none;
    }
    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
    .slick-prev,
    .slick-next {
      display: none;
    }
  }
}
</style>

<style lang="scss">
@import "./assets/styles/variables";
.art {
  .outer-container {
    // padding-bottom: 20px;
    .slick-prev.slick-arrow {
      width: 50px;
      height: 50px;
    }
    .slick-prev:before {
      content: "";
      display: inline-block;
      width: 55px;
      height: 55px;
      background-image: url("/img/back.svg");
      background-position: center;
      background-repeat: no-repeat;
      // @media screen and (max-width: 600px) {
      //   display: none;
      // }
    }
    .slick-dots {
      @media screen and (max-width: 600px) {
        display: none !important;
      }
    }
    .slick-prev {
      position: absolute;
      left: -70px;
      @media screen and (max-width: 600px) {
        top: -30px;
        left: 280px;
        width: 45px;
        height: 45px;
      }
      @media screen and (max-width: 450px) {
        top: -30px;
        left: 200px;
        width: 45px;
        height: 45px;
      }
    }
    .slick-next {
      position: absolute;
      right: -35px;
      @media screen and (max-width: 600px) {
        top: -32.5px;
        right: 10px;
        width: 45px;
        height: 45px;
      }
      @media screen and (max-width: 450px) {
        top: -32.5px;
        right: 10px;
        width: 45px;
        height: 45px;
      }
    }
    .slick-next:before {
      content: "";
      display: inline-block;
      width: 55px;
      height: 55px;
      background-image: url("/img/forward.svg");
      background-position: center;
      background-repeat: no-repeat;
      // @media screen and (max-width: 600px) {
      //   display: none;
      // }
    }
  }
}
</style>
